import styled from 'styled-components';

import { ButtonXL } from '../../components/Button';
import { BodyLarge, H1, Value3, Value4 } from '../../components/Text';
import { OutlineContainer, BaseContainer } from '../../components/Container';
import { LogoAnimated } from '../../components/LogoAnimated';
import useWeb3 from '../../contexts/Web3Context';

const Container = styled(BaseContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 4rem;
  box-sizing: border-box;
  max-height: unset;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 1rem 2rem;
    padding-bottom: 2rem;
  }
`;

const Logo = styled(LogoAnimated)`
  margin: 4rem;

  @media (max-width: ${({ theme }) => theme.tablet}) {
    margin: 2rem;
    width: 6rem;
    height: 6rem;
    min-width: 6rem;
    max-width: 6rem;
    min-height: 6rem;
    max-height: 6rem;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin: 2rem;
    width: 4rem;
    height: 4rem;
    min-width: 4rem;
    max-width: 4rem;
    min-height: 4rem;
    max-height: 4rem;
  }
`;

const HeaderText = styled(H1)`
  text-align: center;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    font-size: 1.5rem;
    padding: 0 1rem;
  }
`;

const BodyText = styled(BodyLarge)`
  margin-top: 2rem;
  color: ${({ theme }) => theme.gray5};
  span {
    color: ${({ theme }) => theme.white};
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 0 1rem;
  }
`;

const Button = styled(ButtonXL)`
  margin-top: 2rem;
  margin-bottom: 4rem;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 0.5rem 1rem;
    width: 100%;
    margin-bottom: 2rem;
  }
`;

const CriteriaHeader = styled(Value3)`
  margin-bottom: 1rem;
`;

const CriteriaItem = styled(Value4)`
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.gray5};
  display: flex;
  flex-direction: row;
`;

function Welcome() {
  const { connect } = useWeb3();

  return (
    <Container>
      <Logo />
      <HeaderText>Welcome to Increment Governance</HeaderText>
      <BodyText as="ul">
        <li>
          The Increment protocol strives to become a fundamental piece of DeFi infrastructure on
          zkSync Era and beyond.
        </li>
        <br />
        <li>
          Token holders can help{' '}
          <span>vote, govern, and curate essential infrastructure components</span> of the Increment
          protocol and contribute to its future.
        </li>
      </BodyText>
      <Button onClick={connect}>Check eligibility for distribution</Button>
      <OutlineContainer>
        <CriteriaHeader>Eligibility Criteria</CriteriaHeader>
        <CriteriaItem as="div">Ranked top 500 in Genesis Orb event </CriteriaItem>
        <CriteriaItem as="div">veCRV Holder, snapshot on March 26, 2024</CriteriaItem>
        <CriteriaItem as="div">Twitter Thread Contest winner</CriteriaItem>
        <CriteriaItem as="div">Emoji and Stickers Contest winner</CriteriaItem>
      </OutlineContainer>
    </Container>
  );
}

export default Welcome;
