import React, { Suspense, useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import { Routes, Route } from 'react-router-dom';

import { ToastContainer } from '../components/Toast';
import { Loader as BaseLoader } from '../components/Loader';
import { useEnsureTermsSigned } from '../hooks/useEnsureTermsSigned';
import useModal from '../contexts/ModalContext';
import { useAccessCheck } from '../hooks/useAccessCheck';
import AccessModal from '../components/Modal/AccessModal';
import Claim from './Claim';

/* const Trade = React.lazy(() => import('./Trade'));
 * const Pools = React.lazy(() => import('./Pools'));
 * const PoolDetails = React.lazy(() => import('./PoolDetails'));
 * const Portfolio = React.lazy(() => import('./Portfolio')); */
/* const Rankings = React.lazy(() => import('./Rankings')); */

const AppWrapper = styled.div<{ blurred: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.2rem 0.25rem;
  height: 100vh;
  overflow: scroll;
  box-sizing: border-box;
  filter: ${({ blurred }) => (blurred ? 'blur(2px)' : 'none')};
`;

const Loader = styled(BaseLoader)`
  color: ${({ theme }) => theme.gray2};
  background: ${({ theme }) => theme.gray2};
  margin: auto;
  height: 100%;

  &:before,
  :after {
    background: ${({ theme }) => theme.gray2};
  }
`;

export default function App() {
  useEnsureTermsSigned();

  const { accessAllowed, error, loading } = useAccessCheck();
  const { setModal } = useModal();

  const showModal = useMemo(
    () => ((!loading && !accessAllowed) || !!error) && process.env.NODE_ENV !== 'development',
    [accessAllowed, error, loading],
  );

  useEffect(() => {
    if (setModal && showModal) {
      setModal?.({
        title: '',
        closeable: false,
        content: <AccessModal />,
      });
    }
  }, [setModal, accessAllowed, error, loading, showModal]);

  return (
    <AppWrapper blurred={showModal}>
      <ToastContainer />
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Claim />} />
        </Routes>
      </Suspense>
    </AppWrapper>
  );
}
