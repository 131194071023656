export interface Network {
  chainId: string;
  chainName: string;
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: 18;
  };
  rpcUrls: string[];
  blockExplorerUrls?: string[];
  contracts: {
    clearingHouse: string;
    clearingHouseViewer: string;
    vault: string;
    curveCryptoViews: string;
    multicall3: string;
    ua: string;
    uaHelper: string;
    merkleDistributor?: string;
    incrementToken?: string;
  };
}

export interface NetworkList {
  [key: string]: Network;
}

export const targetChainId = process.env.REACT_APP_TARGET_CHAIN_ID || '0x144';

export const supportedNetworks: NetworkList = {
  324: {
    chainId: '0x144',
    chainName: 'zkSync Era',
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: [`https://mainnet.era.zksync.io`],
    blockExplorerUrls: ['https://explorer.zksync.io/tx/', 'https://explorer.zksync.io/address/'],
    contracts: {
      clearingHouse: '0x9200536A28b0Bf5d02b7d8966cd441EDc173dE61',
      clearingHouseViewer: '0xc8A34A3cfB835018B800c9A50ab0a71149Da13Fb',
      vault: '0x703500cAF3c79aF68BB3dc85A6846d1C7999C672',
      curveCryptoViews: '0x7b80A367Fd0179CF920391dFfaD376a72724d516',
      multicall3: '0x3082263EC78fa714a48F62869a77dABa0FfeF583',
      ua: '0xfc840c55b791a1DbAF5C588116a8fC0b4859d227',
      uaHelper: '0x54b419b7369e79124424378920b1f25C515EC5d4',
      merkleDistributor: '0x8A7E7eE5457DE6e58422040b78ED6A7eC0d5b83a',
      incrementToken: '0xb2c5a37A4C37c16DDd21181F6Ddbc989c3D36cDC',
    },
  },
  300: {
    chainId: '0x12c',
    chainName: 'zkSync Era sepolia',
    nativeCurrency: {
      name: 'Sepolia Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://sepolia.era.zksync.dev'],
    blockExplorerUrls: [
      'https://explorer.sepolia.era.zksync.dev/tx/',
      'https://explorer.sepolia.era.zksync.dev/address/',
    ],
    contracts: {
      clearingHouse: '0x6C3388fc1dfa9733FeED87cD3639b463Ee072a8a',
      clearingHouseViewer: '0x8888F364774440166B0143910249a2D762766759',
      vault: '0x8D3dc31BBbd43a27bE0224db6F5bd7780a081d84',
      curveCryptoViews: '0x979864867e22f5467259f03A16822759376D8e98',
      multicall3: '0xF9cda624FBC7e059355ce98a31693d299FACd963',
      ua: '0x76e3E4B8E7ad3e072218501269d4c65AE080dC88',
      uaHelper: '0x9bE87B9f0a93933e059e67B3B1d61391EB73F6fB',
    },
  },
};
