import { useSetChain } from '@web3-onboard/react';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useWeb3 from '../contexts/Web3Context';
import { targetChainId } from '../data/chain';
import { MerkleDistributor__factory } from '../typechain';
import { executeTx } from '../utils/clearingHouse';

interface Claim {
  windowIndex: number;
  index: number;
  amount: string;
  proof: string[];
}

interface AirdropData {
  claim?: Claim;
  merkleRoot?: string;
  redeemClaim: () => Promise<void>;
  loading: boolean;
  claimed: boolean;
}

const AIRDROP_INDEX = 1;

export function useAirdrop(): AirdropData {
  const [claim, setClaim] = useState<Claim>();
  const [claimed, setClaimed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [merkleRoot, setMerkleRoot] = useState<string>();
  const [, setChain] = useSetChain();
  const { address, signer, chainConfig, networkError } = useWeb3();

  useEffect(() => {
    let subscribed = true;
    if (address) {
      setLoading(true);
      fetch(`${process.env.REACT_APP_AIRDROP_ENDPOINT}/${AIRDROP_INDEX}/${address.toLowerCase()}`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (subscribed) {
            console.log(address);
            console.log(data);
            setClaim(data.claim);
            setMerkleRoot(data.root);
          }
        })
        .catch(console.error)
        .finally(() => {
          if (subscribed) {
            setLoading(false);
          }
        });
    }
    return () => {
      subscribed = false;
    };
  }, [address]);

  useEffect(() => {
    let subscribed = true;
    if (claim && signer && chainConfig.contracts.merkleDistributor) {
      const merkleDistributor = MerkleDistributor__factory.connect(
        chainConfig.contracts.merkleDistributor,
        signer,
      );
      merkleDistributor
        .isClaimed(claim.windowIndex, claim.index)
        .then((res) => {
          if (subscribed) {
            setClaimed(res);
          }
        })
        .catch(console.error)
        .finally(() => {
          if (subscribed) {
            setLoading(false);
          }
        });
    }
    return () => {
      subscribed = false;
    };
  }, [claim, signer, chainConfig, networkError]);

  const redeemClaim = useCallback(async () => {
    if (
      claim &&
      signer &&
      address &&
      chainConfig.contracts.merkleDistributor &&
      chainConfig.contracts.incrementToken &&
      chainConfig.contracts.multicall3
    ) {
      const chainId = await signer.getChainId();
      if (
        chainId !== +targetChainId &&
        !(await setChain({
          chainId: targetChainId,
        }))
      ) {
        toast.error('User refused to switch chains');
        return;
      }

      const merkleDistributor = MerkleDistributor__factory.createInterface();
      const claimData = merkleDistributor.encodeFunctionData('claim', [
        {
          windowIndex: claim.windowIndex,
          amount: claim.amount,
          accountIndex: claim.index,
          account: address,
          merkleProof: claim.proof,
        },
      ]);
      await executeTx({
        networkId: +targetChainId,
        to: chainConfig.contracts.merkleDistributor,
        input: claimData,
        signer,
        value: 0,
      });
      setClaimed(true);
    }
  }, [claim, signer, address, chainConfig, setChain]);

  return {
    claim,
    merkleRoot,
    redeemClaim,
    loading,
    claimed,
  };
}
