import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import seedrandom from 'seedrandom';
import useLayout from '../../contexts/LayoutContext';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 10rem;
`;

const Row = styled.div<{
  flex: number;
}>`
  flex: ${(props) => props.flex};
  display: flex;
  flex-direction: row;
  transition: all 600ms ease-in-out;
  margin: ${({ flex }) => (flex > 0 ? '0.09375rem 0' : '0')};
`;

const Brick = styled.div<{
  flex: number;
  color: string;
  delay: number;
}>`
  border-radius: 1rem;
  flex: ${(props) => props.flex};
  background-color: ${(props) => props.color};
  margin: ${({ flex }) => (flex > 0 ? '0 0.125rem' : '0')};
  transition: all 600ms ease-in-out;
  transition-delay: ${(props) => props.delay}ms;
`;

export interface BrickData {
  colors: string[];
  maxRows: [number, number]; // [mobile, desktop]
  maxCols: [number, number]; // [mobile, desktop]
  minRowFlex: [number, number]; // [mobile, desktop]
  maxRowFlex: [number, number]; // [mobile, desktop]
  minColFlex: [number, number]; // [mobile, desktop]
  maxColFlex: [number, number]; // [mobile, desktop]
}

interface BrickFillerProps {
  seed: number;
  brickData: BrickData;
}

function BrickFiller({ seed, brickData }: BrickFillerProps) {
  const { isMobile } = useLayout();
  const randInt = (from: number, to: number, rng: seedrandom.PRNG) =>
    Math.floor(rng() * (to - from + 1) + from);

  const { colors, maxRows, maxCols, minRowFlex, maxRowFlex, minColFlex, maxColFlex } = useMemo(
    () => ({
      colors: brickData.colors,
      maxRows: isMobile ? brickData.maxRows[0] : brickData.maxRows[1],
      maxCols: isMobile ? brickData.maxCols[0] : brickData.maxCols[1],
      minRowFlex: isMobile ? brickData.minRowFlex[0] : brickData.minRowFlex[1],
      maxRowFlex: isMobile ? brickData.maxRowFlex[0] : brickData.maxRowFlex[1],
      minColFlex: isMobile ? brickData.minColFlex[0] : brickData.minColFlex[1],
      maxColFlex: isMobile ? brickData.maxColFlex[0] : brickData.maxColFlex[1],
    }),
    [brickData, isMobile],
  );

  const randFlexes = useCallback(
    (n: number, minFlex: number, maxFlex: number, seedPrefix: string) => {
      let acc = 0;
      return Array.from({ length: n }).map((_, i) => {
        const rand = randInt(minFlex, maxFlex, seedrandom(seedPrefix + i + seed));
        acc += rand;
        if (i === n - 1 && acc === 0 && rand === 0) return 1;
        return rand;
      });
    },
    [seed],
  );

  const randColors = useCallback(
    (n: number, seedPrefix: string) => {
      return Array.from({ length: n }).map(
        (_, i) => colors[randInt(0, colors.length - 1, seedrandom(seedPrefix + i + seed))],
      );
    },
    [seed, colors],
  );

  return (
    <Container>
      {Array.from(
        {
          length: isMobile ? maxCols : maxRows,
        },
        (_, i) => i.toString(),
      ).map((iVal, i) => (
        <Row flex={randFlexes(maxRows, minRowFlex, maxRowFlex, 'row')[i]} key={`row${iVal}`}>
          {Array.from(
            {
              length: maxCols,
            },
            (_, j) => j.toString(),
          ).map((jVal, j) => (
            <Brick
              flex={randFlexes(maxCols, minColFlex, maxColFlex, `col${i}`)[j]}
              color={randColors(maxCols, `col${i}`)[j]}
              key={`row${iVal}col${jVal}`}
              delay={i * 100 + j * 100}
            />
          ))}
        </Row>
      ))}
    </Container>
  );
}

export default BrickFiller;
