import styled from 'styled-components/macro';

const ButtonBase = styled.button`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  transition: all ${({ theme }) => theme.fast} ease-out;
  color: ${({ theme }) => theme.white};

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: auto;
    background: ${({ theme }) => theme.bg4};
    border-color: ${({ theme }) => theme.bg4};
    color: ${({ theme }) => theme.gray3};
  }
`;

export const ButtonXL = styled(ButtonBase)<{ alternate?: boolean }>`
  background: ${({ theme, alternate }) => (alternate ? theme.bg2 : theme.blue)};
  border: ${({ theme, alternate }) => (alternate ? `0.125rem solid ${theme.gray1}` : 'none')};
  border-radius: 0.75rem;
  padding: 0.9rem 7rem;
  min-height: 3.25rem;

  &:hover {
    box-shadow: 0rem 0rem 0.5rem 0rem
      ${({ theme, alternate }) => (alternate ? theme.gray1 : theme.blue)};
  }

  &:enabled:active {
    background: ${({ theme, alternate }) =>
      alternate
        ? theme.gray1
        : `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${theme.blue}`};
    box-shadow: none;
  }

  &:disabled {
    box-shadow: none;
  }
`;

export const ButtonL = styled(ButtonBase)`
  background: none;
  border-radius: 0.75rem;
  border: 0.125rem solid ${({ theme }) => theme.blue};
  padding: 0.5rem 1.6rem;

  &:hover {
    filter: drop-shadow(0rem 0rem 0.25rem ${({ theme }) => theme.blue});
  }

  &:enabled:active {
    background: ${({ theme }) => theme.blue};
    filter: none;
  }

  &:disabled {
    filter: none;
  }
`;

export const ButtonM = styled(ButtonL)`
  font-size: 0.875rem;
  letter-spacing: 0.00625rem;
  border-radius: 1rem;
  padding: 0.5rem 0.875rem;
`;

export const ButtonS = styled(ButtonBase)`
  font-size: 0.75rem;
  letter-spacing: 0.00625rem;
  background: ${({ theme }) => theme.bg2};
  border-radius: 0.75rem;
  border: 0.125rem solid ${({ theme }) => theme.gray1};
  padding: 0.25rem 1.125rem;

  &:hover {
    box-shadow: 0rem 0rem 0.25rem ${({ theme }) => theme.gray1};
  }

  &:enabled:active {
    border-color: ${({ theme }) => theme.bg2};
    box-shadow: none;
  }

  &:disabled {
    box-shadow: none;
  }
`;
