import styled from 'styled-components';

import { BodyLarge, H3, Value3 } from '../../components/Text';
import distributionChart from '../../assets/images/distribution.png';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
`;

const Chart = styled.img`
  margin: 2rem;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem 6rem;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 3rem;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;
  }
`;

const PortionTag = styled(Value3)<{ color: string }>`
  background: ${(props) => props.color};
  margin-right: auto;
  padding: 0.25rem 0.625rem;
  border-radius: 0.5rem;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const BodyText = styled(BodyLarge)`
  margin: 0;
`;

export function Distribution() {
  return (
    <Wrapper>
      <Chart src={distributionChart} alt="chart" />
      <Grid>
        <ItemContainer>
          <H3>Community Emissions</H3>
          <PortionTag color="#3C4B90">46%</PortionTag>
          <BodyText>Future token emissions for Increment protocol users</BodyText>
        </ItemContainer>
        <ItemContainer>
          <H3>Community Distribution</H3>
          <PortionTag color="#6B3C90">12%</PortionTag>
          <BodyText>
            — Distribution Phase I is 2%
            <br /> — Distribution Phase II is 2.5% <br /> — Distribution Phase III is 7.5%
          </BodyText>
        </ItemContainer>
        <ItemContainer>
          <H3>Ecosystem</H3>
          <PortionTag color="#903C3C">4%</PortionTag>
          <BodyText>Grants and partnerships</BodyText>
        </ItemContainer>
        <ItemContainer>
          <H3>Core Contributors</H3>
          <PortionTag color="#90643C">15%</PortionTag>
          <BodyText>Current and future core contributor teams</BodyText>
        </ItemContainer>
        <ItemContainer>
          <H3>Seed Investors</H3>
          <PortionTag color="#3C904F">15.6%</PortionTag>
          <BodyText>Seed raise</BodyText>
        </ItemContainer>
        <ItemContainer>
          <H3>Development Fund</H3>
          <PortionTag color="#3C7790">7.4%</PortionTag>
          <BodyText>Reserved for future development or raise</BodyText>
        </ItemContainer>
      </Grid>
    </Wrapper>
  );
}
