import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { BodyLarge, H1, H2, H3, H5 } from '../../components/Text';
import { ButtonS, ButtonXL } from '../../components/Button';
import discourseLogo from '../../assets/images/discourse.png';
import discordLogo from '../../assets/images/discord.png';
import logoFull from '../../assets/images/logo-full.png';
import linkImg from '../../assets/vectors/link-alt.svg';
import incr from '../../assets/images/tokens/incr.png';
import { Distribution } from './distribution';
import { BaseContainer, OutlineContainer } from '../../components/Container';
import useWeb3 from '../../contexts/Web3Context';
import { formatDollarValue } from '../../utils/general';
import TermsOfUse from './termsOfUse';

const Wrapper = styled(BaseContainer)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 3rem 4rem;
  box-sizing: border-box;
  height: auto;
  flex: 1;
  max-height: unset;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 2rem;
    height: auto;
    max-height: unset;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
`;

const LogoFull = styled.img`
  height: 2rem;
`;

const ProgessBar = styled.div<{
  progress: number;
  max: number;
}>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${({ theme }) => theme.gray1};
  height: 0.1875rem;
  width: 8rem;
  border-radius: 0.1875rem;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 6rem;
  }

  &::before {
    content: ${({ progress, max }) => `"${progress}/${max}"`};
    position: absolute;
    font-size: 0.875rem;
    left: -2rem;
  }

  &::after {
    content: '';
    width: ${({ progress, max }) => Math.round((progress / max) * 100)}%;
    height: 100%;
    background: ${({ theme }) => theme.green};
    border-radius: 0.1875rem;
  }
`;

const HeaderText = styled(H1)`
  margin-bottom: 1rem;
  margin-top: 2rem;
`;

const SubHeader = styled(H3)`
  margin-top: 2rem;
`;

const BodyText = styled(BodyLarge)`
  color: ${({ theme }) => theme.gray5};
  margin-bottom: 0rem;

  span {
    color: ${({ theme }) => theme.white};
  }
`;

const List = styled.ul`
  color: ${({ theme }) => theme.gray5};
  padding-left: 1.5rem;

  li {
    margin-bottom: 0.5rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  position: relative
  bottom: 0;
  z-index: 2;
  right: 0;
  left: 0;
  pointer-events: none;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-top: 2rem;
    position: relative;
    padding: 0;
    bottom: 0;
    padding-top: auto;
    gap: 1rem;
  }
`;

const Button = styled(ButtonXL)`
  padding-left: 2rem;
  padding-right: 2rem;
  min-width: 10rem;
  pointer-events: all;
`;

const Link = styled.a`
  font-size: 1rem;
  line-height: 1.375rem;
  letter-spacing: -0.0125rem;
  font-weight: 400;
  color: ${({ theme }) => theme.blueLink};
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  &::after {
    content: url(${linkImg});
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1rem;
    width: 1rem;
    margin-top: 0.25rem;
    margin-left: 0.5rem;
  }
`;

const LinkContainer = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.875rem 1.25rem;
  height: 4.25rem;
  gap: 0.625rem;
  box-sizing: border-box;
  background: ${({ theme }) => theme.bg4};
  border-radius: 0.75rem;
  color: ${({ theme }) => theme.gray5};
  transition: ${({ theme }) => `all ${theme.fast} ease-in-out`};
  text-decoration: none;

  img {
    object-fit: contain;
    height: 1.625rem;
    width: 1.625rem;
  }

  &:hover {
    color: ${({ theme }) => theme.white};
    background: ${({ theme }) => theme.bg5};
    cursor: pointer;
  }
`;

const LinkGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin-top: 1.5rem;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }
`;

const GradientTextWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 2rem;
  padding-bottom: 2rem;
`;

const GradientTextContainer = styled(OutlineContainer)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  margin-top: auto;
`;

const GradientText = styled(H3)`
  background: ${({ theme }) => theme.textgrad0};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const TokenAmount = styled(OutlineContainer)`
  padding: 2rem 1.375rem;
  display: flex;

  h2 {
    display: flex;
    align-items: center;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 1.375rem;
  }
`;

const SuccessHeader = styled.h1`
  position: relative;
  font-weight: 700;
  color: ${({ theme }) => theme.green};
  font-size: 4.5rem;
  text-align: center;
  margin: 5rem 0;
  z-index: 1;

  &::after {
    content: '🎉';
    position: absolute;
    bottom: -3rem;
    right: 7rem;
    transform: scale(1.5);
    z-index: 1;
  }
  &::before {
    content: '🎉';
    position: absolute;
    top: -2rem;
    left: 9rem;
    transform: scale(-0.75, 0.75);
    z-index: -1;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    font-size: 3rem;
    margin: 2rem 0;
    line-height: 3rem;
    &::after {
      bottom: -1rem;
      right: 1rem;
      transform: scale(1);
    }
    &::before {
      top: 1.75rem;
      left: 3.5rem;
      transform: scale(-0.75, 0.75);
    }
  }
`;

const DisconnectButton = styled(ButtonS)`
  margin: auto;
  margin-top: 5rem;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-top: 4rem;
  }
`;

function InvolvedLinks() {
  return (
    <>
      <HeaderText>How to get involved?</HeaderText>
      <LinkGrid>
        <LinkContainer href="https://discourse.increment.finance" target="_blank">
          <img src={discourseLogo} alt="discourse" />
          <H5>Discuss Increment ecosystem improvements</H5>
        </LinkContainer>
        <LinkContainer
          href="https://discourse.increment.finance/c/proposal-discussion/7"
          target="_blank"
        >
          <img src={discourseLogo} alt="discourse" />
          <H5>Create formal proposals</H5>
        </LinkContainer>
        <LinkContainer href="https://discord.increment.finance" target="_blank">
          <img src={discordLogo} alt="discord" />
          <H5>Chat with the community</H5>
        </LinkContainer>
      </LinkGrid>
    </>
  );
}

function Step1() {
  return (
    <>
      <HeaderText>What is Increment?</HeaderText>
      <BodyText>
        The Increment protocol is a decentralized AMM perpetual swaps protocol on zkSync Era,
        featuring automatically concentrated liquidity, dynamic fees and parametrizable pools.
      </BodyText>
      <HeaderText>Why are we launching a token?</HeaderText>
      <BodyText>
        In order to enshrine Increment as a public good that is built and maintained by the
        community, the core contributors and core community members have reached consensus on
        launching the INCR governance token in order to establish an ecosystem around the Increment
        protocol and further its path to decentralization.
      </BodyText>
    </>
  );
}

function Step2() {
  return (
    <>
      <HeaderText>Distribution</HeaderText>
      <BodyText>
        Increment tokens will be distributed to various contributors and community members of the
        ecosystem.
      </BodyText>
      <Link
        href="https://discourse.increment.finance/t/token-creation-distribution-phase-i/134"
        target="_blank"
      >
        Read full proposal
      </Link>
      <Distribution />
    </>
  );
}

function Step3() {
  return (
    <>
      <HeaderText>What exactly is the Increment token and what does it govern?</HeaderText>
      <BodyText>
        The INCR token will be used by the community to vote, govern, and curate essential
        infrastructure components of the Increment ecosystem, including:
      </BodyText>
      <SubHeader>Increment protocol</SubHeader>
      <List>
        <li>Market deployments</li>
        <li>Technical parameters</li>
        <li>Economic risk parameters</li>
        <li>Curation of supported collateral assets</li>
      </List>
      <SubHeader>On-chain assets</SubHeader>
      <List>
        <li>Outstanding INCR token supply</li>
        <li>Other Increment community treasury assets (NFTs, ENS names, etc.)</li>
      </List>
      <SubHeader>Token economy</SubHeader>
      <List>
        <li>Protocol emissions</li>
        <li>Future token utility</li>
      </List>
    </>
  );
}

function Step4() {
  return (
    <>
      <HeaderText>Navigating Increment Governance</HeaderText>
      <BodyText>
        By giving token holders control over the core components of the Increment ecosystem, the
        community is empowered to play an active role in building, maintaining, and contributing to
        the ecosystem.
      </BodyText>
      <InvolvedLinks />
      <GradientTextWrapper>
        <GradientTextContainer>
          <GradientText>
            Now... <br />
            Help build Increment together with INCR
          </GradientText>
        </GradientTextContainer>
      </GradientTextWrapper>
    </>
  );
}

function Step5() {
  return (
    <>
      <HeaderText>Terms of use</HeaderText>
      <BodyText>
        <TermsOfUse />
        <br />
      </BodyText>
    </>
  );
}

function Step6({ claimAmount }: { claimAmount?: string }) {
  return (
    <>
      <HeaderText>Your INCR allocation</HeaderText>
      <TokenAmount>
        <H2>
          {formatDollarValue(claimAmount ?? '0', 18, true, 2)}&nbsp;
          <img src={incr} alt="incr" />
          &nbsp;INCR
        </H2>
      </TokenAmount>
    </>
  );
}

Step6.defaultProps = {
  claimAmount: undefined,
};

function Step7() {
  const { disconnect } = useWeb3();
  return (
    <>
      <SuccessHeader>Claim successful</SuccessHeader>
      <InvolvedLinks />
      <DisconnectButton onClick={disconnect}>Check another wallet</DisconnectButton>
    </>
  );
}

interface Props {
  onWalkthroughUpdate: (step: number, max: number) => void;
  claimed: boolean;
  claimAmount?: string;
  redeemClaim: () => Promise<void>;
}

export function Walkthrough({ onWalkthroughUpdate, claimAmount, claimed, redeemClaim }: Props) {
  const [step, setStep] = useState(0);

  const headerRef = useRef<HTMLDivElement>(null);

  const maxSteps = 7;

  const incStep = () => {
    onWalkthroughUpdate(step + 1, maxSteps);
    setStep((s) => s + 1);
    window.scrollTo({ top: headerRef?.current?.offsetTop, behavior: 'smooth' });
  };

  const decStep = () => {
    onWalkthroughUpdate(step - 1, maxSteps);
    setStep((s) => s - 1);
    window.scrollTo({ top: headerRef?.current?.offsetTop, behavior: 'smooth' });
  };

  useEffect(() => {
    if (claimed) {
      setStep(7);
    }
  }, [claimed]);

  return (
    <>
      <Wrapper>
        <Header ref={headerRef}>
          <LogoFull src={logoFull} alt="logo" />
          <ProgessBar progress={step + 1} max={maxSteps} />
        </Header>
        {(() => {
          if (step === 0) {
            return <Step1 />;
          }
          if (step === 1) {
            return <Step2 />;
          }
          if (step === 2) {
            return <Step3 />;
          }
          if (step === 3) {
            return <Step4 />;
          }
          if (step === 4) {
            return <Step5 />;
          }
          if (step === 5) {
            return <Step6 claimAmount={claimAmount} />;
          }
          if (step === 6) {
            return <Step7 />;
          }
          return <Step1 />;
        })()}
        <ButtonContainer>
          {step > 0 && step + 1 < maxSteps && (
            <Button onClick={decStep} alternate style={{ marginRight: 'auto' }}>
              &lt;- Back
            </Button>
          )}
          {step + 1 < maxSteps && (
            <Button onClick={step === 5 ? redeemClaim : incStep} style={{ marginLeft: 'auto' }}>
              {step === 5 ? 'Claim' : 'Next'}
            </Button>
          )}
        </ButtonContainer>
      </Wrapper>
    </>
  );
}

Walkthrough.defaultProps = {
  claimAmount: undefined,
};
