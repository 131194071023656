function TermsOfUse() {
  return (
    <>
      <b>SUPPLEMENTAL TOKEN AIRDROP TERMS</b>
      <br />
      <p>Last Revised on November 23, 2023</p>
      <p>
        Welcome to the Supplemental Token Airdrop Terms (these “Airdrop Terms”) for the Increment
        (INCR) token airdrop (the “Airdrop”) by Layken Limited (“Company”, “we” or “us”). These
        Airdrop Terms are supplemental to, and incorporate by reference, our general Terms of
        Service (“General Terms”) available at
        https://docs.increment.finance/increment/legal/terms-of-service. Defined terms used but not
        defined herein have the meaning set forth in the General Terms. The Airdrop, and your
        participation in it, is a Service as defined under the General Terms.
      </p>
      <p>
        These Airdrop Terms govern your ability to use our Services in order to participate in the
        Airdrop. Please read these Airdrop Terms carefully, as they include important information
        about your legal rights. By participating in the Airdrop or claiming Airdrop tokens, you are
        agreeing to these Airdrop Terms. If you do not understand or agree to these Airdrop Terms,
        please do not participate in the Airdrop.
      </p>
      <p>
        SECTION 9 OF THE GENERAL TERMS CONTAINS AN ARBITRATION CLAUSE AND CLASS ACTION WAIVER.
        PLEASE REVIEW SUCH CLAUSES CAREFULLY, SINCE IT AFFECTS YOUR RIGHTS. BY AGREEING TO THESE
        AIRDROP TERMS, YOU AGREE TO RESOLVE ALL DISPUTES RELATED TO THE AIRDROP THROUGH BINDING
        INDIVIDUAL ARBITRATION AND TO WAIVE YOUR RIGHT TO PARTICIPATE IN CLASS ACTIONS, CLASS
        ARBITRATIONS OR REPRESENTATIVE ACTIONS, AS SET FORTH IN THE GENERAL TERMS. YOU HAVE THE
        RIGHT TO OPT-OUT OF THE ARBITRATION CLAUSE AND THE CLASS ACTION WAIVER AS EXPLAINED IN
        SECTION 9 OF THE GENERAL TERMS.
      </p>
      <ul>
        <li>
          You agree and acknowledge that you have the sole responsibility and liability for all
          taxes in connection with your participation in the Airdrop and you should consult a tax
          advisor.
        </li>
        <br />
        <li>
          You agree and acknowledge that you (a) may receive tokens for free via the Airdrop (other
          than applicable taxes, if any), (b) were not previously promised any tokens, and (c) took
          no action in anticipation of or in reliance on receiving any tokens or an Airdrop.
        </li>
        <br />
        <li>
          Your eligibility to receive Airdrop tokens or participate in the Airdrop is subject to our
          sole discretion. To the extent you believe you should have received any airdropped tokens
          based on any documentation released by us from time to time, such documentation does not
          entitle you to any tokens or to participate in the Airdrop and therefore you have no claim
          for any such tokens. You agree not to engage in any activities that that are designed to
          obtain more Airdrop tokens than you are entitled to. You agree that you are the legal
          owner of the blockchain address that you use to access or participate in the Airdrop.
        </li>
        <br />
        <li>
          You agree and acknowledge that you are not a Prohibited Person and you will not use a VPN
          or other tool to circumvent any geoblock or other restrictions that we may have
          implemented for Airdrop recipients. Any such circumvention, or attempted circumvention,
          may permanently disqualify you from participation in the Airdrop in our discretion.
        </li>
        <br />
        <li>
          You agree and acknowledge that if you are unable to claim an Airdrop due to technical
          bugs, smart contract issue, gas fees, wallet incompatibility, loss of access to a wallet
          or the keys thereto, or for any other reason, you will have no recourse or claim against
          us or our affiliates and that we and our affiliates will not bear any liability.
        </li>
        <br />
        <li>
          You agree and acknowledge that claiming an Airdrop may require reliance on or an
          integration with third party products (e.g., a wallet or an unaffiliated network or
          blockchain) that we do not control. In the event that you are unable to access such
          products or integrations, or if they fail for any reason, and you are unable to
          participate in an Airdrop or claim Airdrop tokens, you will have no recourse or claim
          against us or our affiliates and we and our affiliates will not bear any liability.
        </li>
        <br />
        <li>
          These Airdrop Terms and the General Terms contain the entire agreement between you and the
          Company regarding the Airdrop, and supersede all prior and contemporaneous understandings
          between the parties regarding the Airdrop. We may modify these Airdrop Terms from time to
          time in which case we will update the “Last Revised” date at the top of these Airdrop
          Terms. The updated Airdrop Terms will be effective as of the time of posting, or such
          later date as may be specified in the updated Airdrop Terms. Your continued access or
          participation in the Airdrop after the modifications have becomes effective will be deemed
          your acceptance of the modified Airdrop Terms.
        </li>
      </ul>
    </>
  );
}

export default TermsOfUse;
