import styled, { keyframes } from 'styled-components';

import meshGradient from '../../assets/images/mesh-gradient.png';
import mask from '../../assets/images/mask.png';

const animation = keyframes`
  0% {
    transform: rotate(0) scale(1.5);
  }
  50% {
    transform: rotate(180deg) scale(1.5);
  }
  100% {
    transform: rotate(360deg) scale(1.5);
  }
`;

const Logo = styled.div`
  width: 8rem;
  height: 8rem;
  min-width: 8rem;
  max-width: 8rem;
  min-height: 8rem;
  max-height: 8rem;
  mask-image: url(${mask});
  mask-repeat: no-repeat;
  mask-size: contain;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 8rem;
    height: 8rem;
    background: url(${meshGradient});
    background-size: 100%;
    transform-origin: center center;
    animation: ${animation} 5s linear infinite;
  }
`;

interface Props {
  className?: string;
}

export function LogoAnimated({ className }: Props) {
  return <Logo className={className} />;
}

LogoAnimated.defaultProps = {
  className: '',
};
