import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const H1 = styled.h1`
  font-size: 1.875rem:
  line-height: 1.5rem;
  font-weight: 600;
  margin: 0;
`;

export const H2 = styled.h2`
  font-size: 1.625rem;
  line-height: 1.5rem;
  font-weight: 600;
  letter-spacing: 0.03125rem;
  margin: 0;
`;

export const H3 = styled.h3`
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: ${({ theme }) => theme.white};
  margin: 0;
`;

export const H4 = styled.h4`
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 400;
  letter-spacing: -0.0125rem;
  margin: 0;
`;

export const H5 = styled.h5`
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  color: ${({ theme }) => theme.white}
  letter-spacing: 0.00625rem;
  margin: 0;
`;

export const Input1 = styled.input`
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 600;
  letter-spacing: 0.00625rem;
`;

export const Input2 = styled.input`
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 600;
  letter-spacing: 0.00625rem;
`;

export const Nav1 = styled(NavLink)`
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 600;
  color: ${({ theme }) => theme.gray3};
  margin: 0;
`;

export const Nav2 = styled.div`
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 600;
  letter-spacing: 0.00625rem;
  margin: 0;
`;

export const Nav3 = styled.div`
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 600;
  margin: 0;
`;

export const Label1 = styled.p`
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 600;
  letter-spacing: 0.00625rem;
  margin: 0;
`;

export const Label2 = styled.div`
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 600;
  margin: 0;
`;

export const Label3 = styled.div`
  font-size: 0.75rem;
  line-height: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.gray4};
  margin: 0;
`;

export const Label4 = styled.div`
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.gray4};
  letter-spacing: 0.00625rem;
  margin: 0;
`;

export const Value = styled.p<{ value?: number | null }>`
  color: ${({ theme, value }) => {
    if (value === undefined || value === 0) {
      return theme.white;
    }
    if (value === null) {
      return theme.gray4;
    }
    if (value > 0) {
      return theme.green;
    }
    return theme.red;
  }};
  margin: 0;
`;

export const Value1 = styled(Value)`
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 600;
  letter-spacing: -0.0125rem;
  margin: 0;
`;

export const Value2 = styled(Value)`
  font-size: 1rem;
  line-height: 1.125rem;
  font-weight: 400;
  letter-spacing: 0.00625rem;
  margin: 0;
`;

export const Value3 = styled(Value)`
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 600;
  letter-spacing: 0.00625rem;
  margin: 0;
`;

export const Value4 = styled(Value)`
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 400;
  letter-spacing: 0.00625rem;
  margin: 0;
`;

export const Value5 = styled(Value)`
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 600;
  margin: 0;
`;

export const TimeStamp = styled.p`
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 400;
  letter-spacing: 0.03125rem;
  margin: 0;
`;

export const Body = styled.p`
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 400;
`;

export const BodyLarge = styled.p`
  font-size: 1rem;
  line-height: 1.375rem;
  letter-spacing: -0.0125rem;
  font-weight: 400;
  color: ${({ theme }) => theme.gray5};
`;

export const Small1 = styled.p`
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.00625rem;
  margin: 0;
`;

export const Small2 = styled.p`
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 400;
  letter-spacing: 0.00625rem;
  margin: 0;
`;

export const Small3 = styled.p`
  font-size: 0.5625rem;
  line-height: 0.655rem;
  font-weight: 400;
  letter-spacing: 0.00625rem;
  margin: 0;
`;

export const Small4 = styled.p`
  font-size: 0.5rem;
  line-height: 0.5rem;
  font-weight: 400;
  margin: 0;
`;

export const LabelTag = styled(Small3)`
  background: ${({ theme }) => theme.bg4};
  padding: 0.25rem 0.375rem;
  border-radius: 0.375rem;
`;
