import { useState, useCallback, useMemo, createContext, useContext, ReactNode } from 'react';

import { Modal } from '../components/Modal';

export interface ModalInfo {
  title: string;
  closeable?: boolean;
  content: ReactNode;
}

interface ModalContextType {
  setModal: (modalInfo: ModalInfo) => void;
  closeModal: () => void;
  modalContent: ReactNode | null;
}

const ModalContext = createContext<ModalContextType>({
  setModal: (modalInfo: ModalInfo) => modalInfo,
  closeModal: () => null,
  modalContent: null,
});

export function ModalProvider({ children }: { children: React.ReactNode }) {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState<ReactNode>(null);
  const [closeable, setCloseable] = useState(true);

  const setModal = useCallback(
    (modalInfo: { title: string; content: ReactNode; closeable?: boolean }) => {
      setTitle(modalInfo.title);
      setContent(modalInfo.content);
      setCloseable(modalInfo.closeable === undefined ? true : modalInfo.closeable);
      setIsOpen(true);
    },
    [setTitle, setContent, setIsOpen, setCloseable],
  );

  const closeModal = useCallback(() => {
    setIsOpen(false);
    setTitle('');
    setContent(null);
  }, [setIsOpen]);

  const modalValues = useMemo(
    () => ({
      setModal,
      closeModal,
      modalContent: content,
    }),
    [setModal, closeModal, content],
  );

  return (
    <ModalContext.Provider value={modalValues}>
      {children}
      {content && (
        <Modal
          isOpen={isOpen}
          title={title}
          content={content}
          closeModal={closeModal}
          closeable={closeable}
        />
      )}
    </ModalContext.Provider>
  );
}
const useModal = () => useContext(ModalContext) as ModalContextType;

export default useModal;
