import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import BrickFiller, { BrickData } from '../../components/BrickFiller';
import { BaseContainer } from '../../components/Container';
import { Loader } from '../../components/Loader';
import useWeb3 from '../../contexts/Web3Context';
import { useAirdrop } from '../../hooks/useAirdrop';
import { Eligibility } from './eligibility';
import { Walkthrough } from './walkthrough';
import Welcome from './welcome';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1;
  max-height: calc(100vh - 0.4rem);

  @media (max-width: ${({ theme }) => theme.mobile}) {
    flex-direction: column;
    height: auto;
    max-height: unset;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin: 0.1875rem 0.125rem;
  min-width: calc(650px - 0.4rem);
  width: calc(650px - 0.4rem);
  max-width: calc(100% - 0.25rem);
  margin-bottom: -0.2rem;
  overflow: scroll;
  box-sizing: border-box;

  > div {
    margin-bottom: 0.325rem;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    overflow: unset;
    flex: 1;
    min-height: 80vh;
    height: auto;
    min-width: unset;
    width: 100%;
    padding: 0;
  }
`;

const LoaderContainer = styled(BaseContainer)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const variants: { [key: string]: BrickData } = {
  normal: {
    colors: [
      '#131621',
      '#131621',
      '#131621',
      '#131621',
      '#131621',
      '#131621',
      '#131621',
      '#131621',
      '#131621',
      '#131621',
      '#131621',
      '#131621',
      '#131621',
      '#131621',
      '#131621',
      '#131621',
      '#191D2F',
      '#1E263A',
      '#17212D',
      '#222339',
    ],
    maxRows: [3, 8],
    minRowFlex: [0, 0],
    maxRowFlex: [1, 5],
    maxCols: [5, 5],
    minColFlex: [0, 0],
    maxColFlex: [3, 3],
  },
  eligible: {
    colors: [
      '#131621',
      '#131621',
      '#131621',
      '#131621',
      '#1C2922',
      '#131621',
      '#131621',
      '#131621',
      '#131621',
      '#131621',
      '#1C2922',
    ],
    maxRows: [3, 8],
    minRowFlex: [0, 0],
    maxRowFlex: [1, 5],
    maxCols: [5, 5],
    minColFlex: [0, 0],
    maxColFlex: [3, 3],
  },
  solved: {
    colors: [
      '#131621',
      '#131621',
      '#131621',
      '#131621',
      '#1C2922',
      '#131621',
      '#131621',
      '#131621',
      '#131621',
      '#131621',
      '#1C2922',
    ],
    maxRows: [3, 8],
    minRowFlex: [1, 1],
    maxRowFlex: [1, 1],
    maxCols: [4, 4],
    minColFlex: [1, 1],
    maxColFlex: [1, 1],
  },
};

type ClaimState = 'welcome' | 'eligibility' | 'claim';

function App() {
  const { connected } = useWeb3();

  const [completed, setCompleted] = useState(false);
  const [seed, setSeed] = useState(Date.now());
  const [claimState, setClaimState] = useState<ClaimState>('welcome');

  const { claim, claimed, redeemClaim, loading } = useAirdrop();

  const incSeed = () => {
    setSeed((s) => s + 1);
  };

  useEffect(() => {
    incSeed();
  }, [claimState, connected]);

  useEffect(
    () => (connected ? setClaimState('eligibility') : setClaimState('welcome')),
    [connected],
  );

  const onWalkthroughUpdate = (step: number, max: number) => {
    if (step === max - 1) {
      setCompleted(true);
    } else {
      setCompleted(false);
    }
    incSeed();
  };

  const variant = useMemo(() => {
    if (claimState === 'eligibility' && !!claim) {
      return variants.eligible;
    }
    if (claimState === 'claim' && completed) {
      return variants.solved;
    }
    return variants.normal;
  }, [claim, claimState, completed]);

  return (
    <Wrapper>
      <BrickFiller seed={seed} brickData={variant} />
      <ContentContainer>
        {(() => {
          if (loading) {
            return (
              <LoaderContainer>
                <Loader />
              </LoaderContainer>
            );
          }
          if (claimState === 'welcome') {
            return <Welcome />;
          }
          if (claimState === 'eligibility' && !claimed) {
            return (
              <Eligibility
                eligible={!!claim}
                amount={claim?.amount}
                onConfirm={() => {
                  setClaimState('claim');
                }}
              />
            );
          }
          if (claimState === 'claim' || claimed) {
            return (
              <Walkthrough
                onWalkthroughUpdate={onWalkthroughUpdate}
                claimAmount={claim?.amount}
                claimed={claimed}
                redeemClaim={redeemClaim}
              />
            );
          }
          return <Welcome />;
        })()}
      </ContentContainer>
      <BrickFiller seed={seed + 2000} brickData={variant} />
    </Wrapper>
  );
}

export default App;
