import styled from 'styled-components';

import diamondLogo from '../../assets/images/diamond-logo.png';
import incr from '../../assets/images/tokens/incr.png';
import { Body, BodyLarge, H1, H2 } from '../../components/Text';
import { ButtonS, ButtonXL } from '../../components/Button';
import useWeb3 from '../../contexts/Web3Context';
import { BaseContainer } from '../../components/Container';
import { formatDollarValue } from '../../utils/general';

const Wrapper = styled(BaseContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 4rem;
  box-sizing: border-box;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 2rem;
    flex: 1;
  }
`;

const Title = styled(H1)`
  position: relative;
  text-align: center;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  z-index: 1;

  span {
    color: ${({ theme }) => theme.green};
  }

  &::before {
    content: url(${diamondLogo});
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-top: 6rem;
  }
`;

const ContinueContainer = styled.div`
  margin-top: 4rem;
  background: ${({ theme }) => theme.bg2};
  border: 1px solid ${({ theme }) => theme.bg5};
  padding: 1.5rem;
  border-radius: 0.5rem;
  z-index: 1;
  display: flex;
  flex-direction: row;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
    flex-direction: column;
    gap: 1.375rem;
  }
`;

const ContinueText = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
  gap: 0.5rem;
  text-align: left;
`;

const ContinueSubHeader = styled(Body)`
  color: ${({ theme }) => theme.gray5};
  margin: 0;
`;
const ContinueHeader = styled(H2)`
  display: flex;
  align-items: center;
`;

const ClaimButton = styled(ButtonXL)`
  padding-left: 2rem;
  padding-right: 2rem;
`;

const DisconnectButton = styled(ButtonS)`
  margin-top: 1rem;
`;

const IneligibleText = styled(BodyLarge)`
  margin-top: 2rem;
  color: ${({ theme }) => theme.gray5};
  a {
    font-weight: 700;
    color: ${({ theme }) => theme.gray5};
    &::visited {
      color: ${({ theme }) => theme.gray5};
    }
  }
`;

interface Props {
  eligible?: boolean;
  amount?: string;
  onConfirm: () => void;
}

export function Eligibility({ onConfirm, eligible, amount }: Props) {
  const { address, disconnect } = useWeb3();

  return (
    <Wrapper>
      {eligible ? (
        <>
          <Title>
            <span>Congrats!</span>
            <br />
            You are eligible for the airdrop
          </Title>
          <BodyLarge>You will be able to claim your tokens</BodyLarge>
          <ContinueContainer>
            <ContinueText>
              <ContinueSubHeader>
                {address?.slice(0, 5)}...{address?.slice(-4)} will receive
              </ContinueSubHeader>
              <ContinueHeader>
                {formatDollarValue(amount ?? '0', 18, true, 2)}&nbsp;
                <img src={incr} alt="incr" />
                &nbsp;INCR
              </ContinueHeader>
            </ContinueText>
            <ClaimButton onClick={onConfirm}>Start claim process</ClaimButton>
          </ContinueContainer>
        </>
      ) : (
        <>
          <H1>Sorry, you are not eligible for this airdrop round.</H1>
          <IneligibleText>
            Don’t worry! There will be plenty of opportunities to earn <b>INCR</b> in the future.
            Get started in the <a href="https://discord.increment.finance/">Increment community</a>{' '}
            today.
          </IneligibleText>
          <DisconnectButton onClick={disconnect}>Try another wallet</DisconnectButton>
        </>
      )}
    </Wrapper>
  );
}

Eligibility.defaultProps = {
  eligible: false,
  amount: undefined,
};
